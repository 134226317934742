import './partials/introAnimation';
import './partials/header';


const sideTriggers = document.querySelectorAll('[data-sides-trigger]');

sideTriggers && sideTriggers.forEach(sideTrigger => {

  sideTrigger.addEventListener('pointerover', ev => {
    if (ev?.pointerType !== 'mouse') {
      return;
    }

    sideTriggers.forEach(
        el => (el !== sideTrigger) && (el.dataset.sidesTrigger = ''));
    sideTrigger.dataset.sidesTrigger = 'active';
  });
  sideTrigger.addEventListener('pointerout', ev => {
    if (ev?.pointerType !== 'mouse') {
      return;
    }
    sideTrigger.dataset.sidesTrigger = '';
  });

  sideTrigger.addEventListener('click', ev => {

    // debugger;
    if (ev?.pointerType !== 'mouse') {
      if (sideTrigger.dataset?.sidesTrigger !== 'active' &&
          sideTrigger.contains(ev.target)) {
        ev.stopPropagation();
        ev.preventDefault();
      }

      sideTriggers.forEach(
          el => (el !== sideTrigger) && (el.dataset.sidesTrigger = ''));
      sideTrigger.dataset.sidesTrigger = 'active';
    }
  }, {capture: true});
  // }, {});
});

const sideLink = document.querySelector('.side__link');

sideLink && sideLink.addEventListener('click', (ev) => {

  // debugger;

  console.log('sideLinkClick');

  const isActive = sideLink.matches('[data-sides-trigger="active"] *');

  console.log('matches', isActive);

  if (ev?.pointerType !== 'mouse' && !isActive) {
    ev.preventDefault();
  }
  // debugger;
  // const headingEl = sideLink.querySelector('.side__heading');
  // if(headingEl && (headingEl.offsetParent === null || !headingEl.matches(':hover'))) {
  //   ev.preventDefault();
  //   return false;
  // }
}, {capture: true});



