import {playAnimation, playAnimationGroup} from './promiseAnimation';

// animate logo in

(async () => {
  const logoWrapperEl = document.querySelector('.logo-wrapper');

  if (!logoWrapperEl) {
    return;
  }

  const headerLogo = document.querySelector('#site-header .header__logo');

  const logoSvg = logoWrapperEl.querySelector('svg.logo');
  const logoLetters = logoSvg.querySelectorAll('#c > g > *');

  if (!logoLetters) {
    return;
  }

  const animatedLetters = [...logoLetters].map((letter) => {
    const animation = letter.animate([
      {
        opacity: 0,
        clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
      },
      {
        opacity: 1,
      },
      {
        opacity: 1,
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      },
    ], {
      duration: 800,
      fill: 'forwards',
      easing: 'ease-in',
    });
    animation.pause();
    return animation;
  });

  // FLIP technique
  // headerLogoPosition and Size
  // wrapper Logo Position and Size
  // calculate difference
  // set transform origin to center
  const headerLogoRect = headerLogo.querySelector('svg').
      getBoundingClientRect();
  const headerLogoSize = headerLogoRect.height * headerLogoRect.width;
  const logoRect = logoSvg.getBoundingClientRect();
  const logoSize = logoRect.height * logoRect.width;
  const scaleAdjust = headerLogoSize / logoSize;
  const yAdjust = Math.round(((logoRect.top + logoRect.height / 2) -
      (headerLogoRect.top + headerLogoRect.height / 2)));
  const xAdjust = Math.round(((logoRect.left + logoRect.width / 2) -
      (headerLogoRect.left + headerLogoRect.width / 2)));
  // 2x for transform origin center
  const transform = `translate(-${xAdjust}px, -${yAdjust}px) scale(${scaleAdjust *
  2})`;
  logoSvg.style.transformOrigin = '50% 50%';
  // logoSvg.style.transform = transform;
  const animateLogoToHeader = logoSvg.animate([
    {
      transform: '',
    },
    {
      transform: transform,
    },
  ], {
    duration: 1000,
    fill: 'forwards',
  });
  animateLogoToHeader.pause();

  const animateWrapperOut = logoWrapperEl.animate([
    {opacity: 1},
    {
      opacity: 0,
    },
  ], {
    duration: 700,
    fill: 'forwards',
    easing: 'ease-in',
  });
  animateWrapperOut.pause();

  //block scroll
  document.body.style.overflowY = 'hidden';

  await playAnimationGroup(animatedLetters);
  await playAnimation(animateLogoToHeader);
  await playAnimation(animateWrapperOut);
  logoWrapperEl.style.display = 'none';

  // flash side text
  const sideTexts = document.querySelectorAll('.side__text');
  const sideTextsAnimations = sideTexts &&
      [...sideTexts].map((sideText, index) => {
        const initialStyle = getComputedStyle(sideText);
        const animation = sideText.animate([
          {color: '#ffffff66', offset: 0.3},
          {color: initialStyle.color},
        ], {
          duration: 300,
          delay: index * 120,
          easing: 'ease-out',
        });

        animation.pause();

        return animation;
      });

  const sideTriggers = document.querySelectorAll('[data-sides-trigger]');
  let sideTriggersTriggered = false;
  let repeatAnimationId = null;
  const cancelSideTextAnimation = () => {
    sideTriggersTriggered = true;
    if (repeatAnimationId) {
      clearTimeout(repeatAnimationId);
    }
  };
  sideTriggers && sideTriggers.forEach((sideTrigger) => {
    sideTrigger.addEventListener('pointerover', cancelSideTextAnimation,
        {
          once: true,
        });
    sideTrigger.addEventListener('click', cancelSideTextAnimation,
        {
          once: true,
        });
  });

  const repeatAnimation = () => {
    repeatAnimationId = setTimeout(() => {
      playAnimationGroup(sideTextsAnimations).then(() => {
        if (!sideTriggersTriggered) {
          repeatAnimation();
        }
      });
    }, 3000);
  };

  playAnimationGroup(sideTextsAnimations).then(() => {
    repeatAnimation();
  });

  // unblock scroll
  document.body.style.overflowY = '';
})();
