const siteHeader = document.querySelector('#site-header');
const menuToggle = document.querySelector('[data-main-menu-toggle]');
const mainMenu = document.querySelector('[data-main-menu]');

function isMenuActive() {
  return !!siteHeader && siteHeader.dataset?.mainMenuActive !== undefined;
}

function showMenu() {
  // activate
  document.body.style.overflowY = 'hidden';
  menuToggle.dataset.mainMenuActive = '';
  menuToggle.ariaExpanded = 'true';
  siteHeader.dataset.mainMenuActive = '';
  mainMenu && mainMenu.classList.add('menu--active');
}

function hideMenu() {
  // deactivate
  document.body.style.overflowY = '';
  delete menuToggle.dataset.mainMenuActive;
  menuToggle.ariaExpanded = 'false';
  delete siteHeader.dataset.mainMenuActive;
  mainMenu && mainMenu.classList.remove('menu--active');
}

menuToggle && menuToggle.addEventListener('click', () => {
  if (isMenuActive()) {
    hideMenu();
  } else {
    showMenu();
  }
});

document.body.addEventListener('keydown', (ev) => {
  if ((ev.key === 'Escape' || ev.key === 'esc') && isMenuActive()) {
    hideMenu();
  }
});
