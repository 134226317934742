export const playAnimation = (animation) => {
  return new Promise((resolve, reject) => {
    animation.onfinish = () => resolve(animation);
    animation.oncancel = () => reject(animation);
    animation.play();
  });
};

export const playAnimationGroup = (animations) => {
  return Promise.all(animations.map((animation) => playAnimation(animation)));
};
